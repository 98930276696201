import {
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonRow,
    useIonViewWillEnter,
    useIonViewWillLeave,
} from "@ionic/react";
import "../../styles/App.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";
import React, { useEffect, useState } from "react";

import { RouteComponentProps } from "react-router";

import { businessOutline, listOutline, walletOutline, documentAttachOutline, documentAttach, documentTextOutline, documentText, business, list } from "ionicons/icons";

import ToolbarBack from "../../components/Toolbars/ToolbarBack";

import { getInformeById } from "../../store/ducks/informes/actions";
import InformeItem from "../../components/Copropietario/Informes/InformeItem";
import InformeDescripcionCard from "../../components/Copropietario/Informes/InformeDescripcionCard";

type Params = { informeId: string };

const InformeCopDetail: React.FC<RouteComponentProps<Params>> = ({
    match: {
        params: { informeId },
    },
}) => {

    const { informeById } = useSelector((state: RootState) => state.informes);
    const [informeLoad, setInformeLoad] = useState(false);
    const dispatch = useDispatch();

    // Cuando la vista está a punto de entrar
    useIonViewWillEnter(() => {
        // Inicializa informeLoad como false al entrar en la vista
        setInformeLoad(false);
        // Hace el dispatch para obtener el nuevo informe con el nuevo informeId
        dispatch(getInformeById(informeId));
    });

    // Cuando la vista está a punto de salir
    useIonViewWillLeave(() => {
        // Puedes limpiar cualquier estado aquí si es necesario
    });

    // Cuando el nuevo informe se carga completamente, cambiar informeLoad a true
    useEffect(() => {
        if (informeById._id === informeId) {
            // Verifica si el informe cargado en Redux coincide con el informeId actual
            setInformeLoad(true);
        }
    }, [informeById, informeId]);

    return (
        <IonPage>
            <IonHeader className="ion-no-border border-header">
                <ToolbarBack title="Informe" backLink="/copropietario/informes" />
            </IonHeader>


            <IonContent fullscreen className="ion-content-grey">
           
                {informeLoad && (
                    <IonGrid >

                        <IonRow className="dashboard-row">
                            <IonCol size-md="12" size-lg="12" size-xs="12" size-sm="12">
                                <InformeDescripcionCard
                                    descripcion={informeById.descripcion}
                                    fechaVisita={informeById.fechaVisita}
                                    unidadesVisitadas={informeById.unidadesVisitadas}
                                />
                            </IonCol>

                        </IonRow>



                        <IonRow className="dashboard-row">


                            <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                <InformeItem
                                    link={`/copropietario/informes/archivos/${informeId}`}
                                    title="Informe"
                                    subTitle="Archivo en formato PDF"
                                    iconName={documentAttach}
                                />
                            </IonCol>
                            <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                <InformeItem
                                    link={`/copropietario/informes/memorias/${informeId}`}
                                    title="Memorias"
                                    subTitle="Memorias asociadas"
                                    iconName={documentText}
                                />
                            </IonCol>

                            <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                <InformeItem
                                    link={`/copropietario/informes/obras/${informeId}`}
                                    title="Obras"
                                    subTitle="Obras asociadas"
                                    iconName={business}
                                />
                            </IonCol>
                            <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                <InformeItem
                                    link={`/copropietario/informes/notas/${informeId}`}
                                    title="Notas"
                                    subTitle="Anotaciones y recordatorio"
                                    iconName={list}
                                />
                            </IonCol>
                        </IonRow>

                    </IonGrid>
                    
                    
                )}

            </IonContent>


        </IonPage>
    );
};

export default InformeCopDetail;