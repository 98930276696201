import React, { useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonModal,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonTitle,
  IonPopover,
  IonContent,
  IonFooter,
} from "@ionic/react";
import { chatboxEllipsesOutline, close } from "ionicons/icons";
import "./GaleriaFotos.css";

import { ImagenObra } from "../../store/ducks/observaciones/types";

type GaleriaFotosProps = {
  imagenes: ImagenObra[];
};

const GaleriaFotos: React.FC<GaleriaFotosProps> = ({ imagenes }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedImageComment, setSelectedImageComment] = useState<string | undefined>(undefined);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [showAllImages, setShowAllImages] = useState(false);

  const [touchScale, setTouchScale] = useState(1);
  const [initialDistance, setInitialDistance] = useState<number | null>(null);

  const handleZoom = (e: React.WheelEvent<HTMLDivElement>) => {
    e.preventDefault();

    const container = e.currentTarget;
    const img = container.querySelector("img") as HTMLImageElement;
    if (!img) return;

    let scale = parseFloat(img.getAttribute("data-scale") || "1");
    scale += e.deltaY * -0.01; // Ajusta la velocidad del zoom
    scale = Math.min(Math.max(1, scale), 4); // Límites de escala

    // Calcula la posición del cursor en el contenedor
    const rect = container.getBoundingClientRect();
    const offsetX = ((e.clientX - rect.left) / rect.width) * 100;
    const offsetY = ((e.clientY - rect.top) / rect.height) * 100;

    // Aplica el zoom centrado en el cursor
    img.style.transformOrigin = `${offsetX}% ${offsetY}%`;
    img.style.transform = `scale(${scale})`;
    img.setAttribute("data-scale", scale.toString());
    setTouchScale(scale);
  };

  const handleDoubleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const container = e.currentTarget;
    const img = container.querySelector("img") as HTMLImageElement;
    if (!img) return;
  
    // Obtiene el valor actual de la escala
    let scale = parseFloat(img.getAttribute("data-scale") || "1");
  
    if (scale === 1) {
      // Realiza zoom (por ejemplo, 2x)
      scale = 2;
  
      // Calcula la posición del cursor dentro del contenedor
      const rect = container.getBoundingClientRect();
      const offsetX = ((e.clientX - rect.left) / rect.width) * 100;
      const offsetY = ((e.clientY - rect.top) / rect.height) * 100;
  
      img.style.transformOrigin = `${offsetX}% ${offsetY}%`;
      img.style.transform = `scale(${scale})`;
    } else {
      // Restablece la escala a 1 (tamaño original)
      scale = 1;
      img.style.transform = `scale(${scale})`;
      img.style.transformOrigin = "center center";
    }
  
    // Actualiza los atributos y estado
    img.setAttribute("data-scale", scale.toString());
    setTouchScale(scale);
  };
  

  const handlePinchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    if (e.touches.length === 2) {
      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      const deltaX = touch2.clientX - touch1.clientX;
      const deltaY = touch2.clientY - touch1.clientY;
      const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
      setInitialDistance(distance); // Guarda la distancia inicial
    }
  };
  const handlePinchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (e.touches.length === 2 && initialDistance !== null) {
      const container = e.currentTarget;
      const img = container.querySelector("img") as HTMLImageElement;
      if (!img) return;

      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      const deltaX = touch2.clientX - touch1.clientX;
      const deltaY = touch2.clientY - touch1.clientY;
      const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

      // Solo aplica zoom si la distancia cambia significativamente
      if (Math.abs(distance - initialDistance) > 10) {
        const rect = container.getBoundingClientRect();
        const offsetX = (((touch1.clientX + touch2.clientX) / 2 - rect.left) / rect.width) * 100;
        const offsetY = (((touch1.clientY + touch2.clientY) / 2 - rect.top) / rect.height) * 100;

        const scale = Math.min(Math.max(1, touchScale * (distance / initialDistance)), 4); // Limita el zoom entre 1x y 4x
        img.style.transformOrigin = `${offsetX}% ${offsetY}%`;
        img.style.transform = `scale(${scale})`;
        setTouchScale(scale);
      }
    }
  };
  const handlePinchEnd = () => {
    setInitialDistance(null); // Reinicia la distancia inicial
  };

  const openImage = (imgUrl: string, imageComment: string | undefined) => {
    setSelectedImage(imgUrl);
    setSelectedImageComment(imageComment || "Sin comentario");
    setTouchScale(1);
    setIsOpen(true);
  };

  const handleToggleImages = () => {
    setShowAllImages(!showAllImages);
  };

  const visibleImages = showAllImages ? imagenes : imagenes.slice(0, 8);

  return (
    <div className="gallery-box">
      <IonGrid style={{ padding: 0, margin: 0 }}>
        <IonRow style={{ padding: 0, margin: 0 }}>
          {visibleImages.map((imagen) => (
            <IonCol key={imagen._id} size="6" size-md="4" size-lg="3">
              <div style={{ overflow: "hidden", borderRadius: "15px" }}>
                <IonImg
                  src={imagen.imgUrl}
                  onClick={() => openImage(imagen.imgUrl, imagen.comentario)}
                  className="img-galeria-observacion"
                />
              </div>
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>

      {imagenes.length > 8 && (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
          <IonButton
            className="rounded-button"
            fill="outline"
            expand="block"
            onClick={handleToggleImages}
          >
            {showAllImages ? "Ver menos imágenes" : "Ver más imágenes"}
          </IonButton>
        </div>
      )}

      <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)} className="image-modal">
        <IonHeader className="ion-no-border border-header">
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setIsOpen(false)}>
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle>Imagen</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="image-modal-content">
          <div
            className="zoom-container"
            onWheel={handleZoom}
            onTouchStart={handlePinchStart}
            onTouchMove={handlePinchMove}
            onTouchEnd={handlePinchEnd}
            onDoubleClick={handleDoubleClick}
          >
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Imagen seleccionada"
                className="img-gallery"
                data-scale="1"
              />
            )}
          </div>
        </IonContent>

        <IonFooter className="image-gallery-footer">
          <IonToolbar className="image-gallery-footer-toolbar">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <IonButton fill="clear" onClick={() => setPopoverOpen(true)}>
                <IonIcon icon={chatboxEllipsesOutline} className="footer-icon" />
              </IonButton>
            </div>
          </IonToolbar>
        </IonFooter>

        <IonPopover
          isOpen={popoverOpen}
          onDidDismiss={() => setPopoverOpen(false)}
          className="comment-pop-over"
        >
          <IonContent className="ion-padding">
            <span className="font-bold comentario-label">Comentario:</span>
            {selectedImageComment}
          </IonContent>
        </IonPopover>
      </IonModal>
    </div>
  );
};

export default GaleriaFotos;