import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    useIonViewDidEnter,
    useIonViewWillEnter,
} from "@ionic/react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";

import ToolbarBack from "../../components/Toolbars/ToolbarBack";
import { getInformeById } from "../../store/ducks/informes/actions";
import { getObraById } from "../../store/ducks/obras/actions";
import { getVisitasByObra } from "../../store/ducks/visitas/actions";
import ObraListItem from "../../components/Copropietario/Obras/ObraListItem";
import CustomSpinner from "../../components/Spinner/CustomSpinner";
import { IObra } from "../../store/ducks/obras/types";
import { IVisita } from "../../store/ducks/visitas/types";
import VisitaListItem from "../../components/Copropietario/Visitas/VisitaListItem";

type Params = { obraId: string | undefined };

const ObraCopSeguimiento: React.FC<RouteComponentProps<Params>> = ({
    match: {
        params: { obraId },
    },
}) => {
    const { _id, userProfile } = useSelector((state: RootState) => state.users);
    const { obraByIdLoad, obraById } = useSelector((state: RootState) => state.obras);
    const { visitasByObra, visitasLoad } = useSelector((state: RootState) => state.visitas);
    const dispatch = useDispatch();
    useEffect(() => {
        console.log(visitasByObra);
    }, [visitasByObra]);
    useIonViewWillEnter(() => {
        if (obraId && !visitasLoad) dispatch(getVisitasByObra(obraId));
    });


    return (
        <IonPage>
            <IonHeader className="ion-no-border border-header">
                <ToolbarBack title="Seguimiento" backLink={`/copropietario/obras/${obraId}`} />
            </IonHeader>

            <IonContent fullscreen className="ion-content-grey">
                <IonGrid className="pb-50">
                    <IonRow className="dashboard-row">
                        {!visitasLoad ? (
                            <IonCol size="12" className="center-content">
                                <CustomSpinner color="primary" />
                            </IonCol>
                        ) : visitasByObra.length > 0 ? (
                            visitasByObra.map((visita: IVisita) => (
                                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6" key={visita._id}>
                                    <VisitaListItem
                                        link={`/copropietario/obras/seguimiento/${obraId}/visitas/${visita._id}`}
                                        alt="visita"
                                        visita={visita}
                                    />
                                </IonCol>
                            ))
                        ) : (
                            <IonCol size="12" className="center-content">
                                <p>No se encontraron visitas.</p>
                            </IonCol>
                        )}

                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default ObraCopSeguimiento;