import React from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
} from "@ionic/react";
import "../../styles/App.css";
import "./ObrabitPresentacion.css";

const ObrabitPresentation: React.FC = () => {
    return (
        <IonPage>
            <IonHeader className="ion-no-border">
                <IonToolbar className="services-toolbar">
                    {/* Logo */}
                    <div className="ion-logo-servicios" slot="start"></div>

                    {/* Botón de Login */}
                    <IonButton slot="end" shape="round" fill="outline" size="small" className="login-toolbar-btn" routerLink="/">
                        Login
                    </IonButton>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className="ion-content-grey">
                <IonGrid className="pb-50 pt-50">

                    <IonRow className="dashboard-row">

                        <IonCol size="12">

                            <h3 className="services-title">Sobre nosotros</h3>
                        </IonCol>
                    </IonRow>
                    {/* Lema */}
                    <IonRow className="dashboard-row">

                        <IonCol size="12">
                            <h2>Cuidamos el estado de tu edificio con gestión técnica precisa y tecnología para documentar cada detalle</h2>
                        </IonCol>
                    </IonRow>

                    {/* Descripción */}
                    <IonRow className="dashboard-row">
                        <IonCol size="12">
                            <p>
                                Somos un equipo de arquitectos que colabora con copropietarios para mejorar y mantener la calidad
                                edilicia de edificios de propiedad horizontal.
                            </p>
                            <p>
                                Nuestro enfoque combina experiencia técnica con una aplicación digital que promueve una comunicación eficiente y optimiza los recursos destinados al mantenimiento y
                                la preservación del valor de las propiedades.
                            </p>
                            <p>
                                Dividimos nuestro servicio en dos áreas principales:
                            </p>
                            <p>
                                <span style={{ fontWeight: "600" }}>Asesoramiento técnico </span>para abordar necesidades específicas de mantenimiento y mejoramiento edilicio.
                            </p>

                            <p>
                                <span style={{ fontWeight: "600" }}>Gestión de información</span> que incluye documentación técnica e información aportada por los copropietarios considerada fundamental para garantizar la eficiencia y sostenibilidad de los recursos destinados al cuidado del edificio.                           </p>
                        </IonCol>
                    </IonRow>
                    <IonRow className="dashboard-row pt-30">

                        <IonCol size="12">
                            <h3 className="services-title">Asesoramiento técnico</h3>
                        </IonCol>
                    </IonRow>
                    <IonRow className="dashboard-row">

                        <IonCol size="12" className="service-card">

                            <h4 className="font-18">Diagnóstico técnico</h4>
                            <p>
                                Evaluamos el estado edilicio mediante informes técnicos
                                detallados para detectar problemas y prevenir futuros
                                desperfectos.
                            </p>
                        </IonCol>
                        <IonCol size="12" className="service-card">
                            <h4 className="font-18">Gestión y control de obras</h4>
                            <p>
                                Seguimos exhaustivamente cada obra, asegurando que se realice
                                dentro del presupuesto, en tiempo y con los resultados
                                esperados.
                            </p>
                        </IonCol>
                        <IonCol size="12" className="service-card">
                            <h4 className="font-18">Memorias constructivas</h4>
                            <p>
                                Creamos especificaciones técnicas claras que permiten a las
                                empresas constructoras ejecutar trabajos con eficiencia y
                                precisión.
                            </p>
                        </IonCol>
                        <IonCol size="12" className="service-card">
                            <h4 className="font-18">Presupuestos</h4>
                            <p>
                                Realizamos un seguimiento de la solicitud de presupuestos para obras. Evaluamos las propuestas y verificamos
                                que cumplan con todos los requisitos establecidos en la solicitud.
                            </p>
                        </IonCol>
                    </IonRow>

                    <IonRow className="dashboard-row pt-30">

                        <IonCol size="12">

                            <h3 className="services-title">Gestión de información</h3>
                        </IonCol>

                    </IonRow>
                    <IonRow className="dashboard-row">
                        <IonCol size="12">
                            <p>
                                Nuestra aplicación ofrece herramientas para facilitar la organización y comunicación en la gestión edilicia.
                            </p>
                        </IonCol>
                        <IonCol size="12" className="service-card">

                            <h4 className="font-18">Reportes de desperfectos</h4>
                            <p>
                                Comunicación directa de problemas edilicios como humedades, fallas sanitarias o problemas estructurales.
                            </p>
                        </IonCol>
                        <IonCol size="12" className="service-card">
                            <h4 className="font-18">Información para el seguimiento de servicios</h4>
                            <p>
                                Datos del servicio: Horarios, datos de contacto de la empresa responsable y descripción
                                detallada del servicio, incluyendo tareas realizadas e insumos utilizados.
                            </p>
                            <p>
                                Reporte de incidencias: Registro de problemas detectados durante la prestación del servicio,
                                con notas sobre la calidad o cualquier observación técnica.
                            </p>
                            <p>
                                Historial mensual: Registro ordenado con detalles como gastos asociados, insumos utilizados
                                y costos adicionales.
                            </p>
                        </IonCol>
                        <IonCol size="12" className="service-card">
                            <h4 className="font-18">Gestión documental por copropietarios</h4>
                            <p>
                                Espacio para subir documentos relevantes que se organizan por mes.
                            </p>
                            <p>
                                Ejemplos de documentación que podrían incluir:
                                Copias de comprobantes de pagos relacionados con el mantenimiento,
                                listas de insumos adquiridos o utilizados en actividades comunes,
                                informes o notas sobre reparaciones menores,
                                propuestas o presupuestos obtenidos para servicios puntuales,
                                actas informales de reuniones entre copropietarios
                            </p>

                        </IonCol>
                        <IonCol size="12" className="service-card">
                            <h4 className="font-18">Avisos generales</h4>
                            <p>
                                Publicación de notificaciones importantes para mantener informados a los copropietarios.
                            </p>
                        </IonCol>
                        <IonCol size="12" className="service-card">
                            <h4 className="font-18">Acceso a documentación técnica</h4>
                            <p>
                                Los copropietarios pueden consultar toda la documentación elaborada por el equipo técnico, como los informes, memorias constructivas y seguimiento detallado de obras.
                            </p>
                        </IonCol>
                    </IonRow>
                    {/* Servicios */}


                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default ObrabitPresentation;
