import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
} from "@ionic/react";
import "./ObraItem.css";

import { businessOutline, chevronForwardOutline, arrowForwardOutline } from 'ionicons/icons';
import React, { ReactElement } from "react";
import moment from "moment";
import "moment/locale/es";
import { FiCalendar } from "react-icons/fi";

export type ObraItemsProps = {
  title: string;
  subTitle: string;
  link: string;
  iconName: string;
};

const ObraItem: React.FC<ObraItemsProps> = ({
  link,
  title,
  subTitle,
  iconName
}) => {
  return (
    <IonCard className="ion-card-menu" routerLink={link} routerDirection="root">

      <IonCardContent className="ion-card-item-content">
        <div className="d-flex align-items-center obra-item-box">
          <IonIcon icon={iconName} className="ion-icon-obra-item float-left"></IonIcon>
          <div className="obra-item-text">
            <h4 className="font-weight-500 text-capitalize font-dark-grey float-left ml-2">
              {title}
            </h4>
            <p className="ml-2">
              {subTitle}
            </p>
          </div>

          <IonIcon icon={chevronForwardOutline} className="ion-icon-obra-forward float-right"></IonIcon>
        </div>

      </IonCardContent>
    </IonCard>
  );
}

export default ObraItem;