import React, { useEffect, useState, Suspense, useCallback } from 'react';
import { IonToast, IonRouterOutlet, useIonRouter } from "@ionic/react";
import { Redirect, useLocation } from "react-router-dom";
import { Route } from "react-router-dom";
import LoginRoute from "../routes/LoginRoute";
import PrivateRoutes from "../routes/PrivateRoute";
import CenteredLoader from '../components/CenteredLoader/CenteredLoader';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuthStatus, getUserProfileRequest, logout, resetNewContentAvailable } from '../store/ducks/users/actions';
import { App as CapacitorApp } from '@capacitor/app';
import { RootState } from '../store/ducks/rootReducer';
import Login from './Login/Login';
import MenuCopropietario from './MenuCopropietario/MenuCopropietario';
import { getUserId } from '../utils/auth';
import ObrabitPresentation from './ObrabitPresentacion/ObrabitPresentacion';

// Carga diferida
//const MenuCopropietario = React.lazy(() => import('./MenuCopropietario/MenuCopropietario'));


const Main: React.FC = () => {
    const { isLogged, tipo, newContentAvailable, checkAuthFail, checkingAuth } = useSelector((state: RootState) => state.users);
    const [profileLoaded, setProfileLoaded] = useState(false);
    const [toastIsOpen, setToastIsOpen] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigation = useIonRouter();

    const pathByType = (tipo: string) => {
        let routePath: string = "";
        if (tipo === "copropietario")
            routePath = "/copropietario";
        if (tipo === "tecnico")
            routePath = "/tecnico";
        return routePath
    };

    const handleReload = () => {
        setToastIsOpen(false);
        dispatch(resetNewContentAvailable());
        reloadPage();
    };
    const reloadPage = useCallback(() => {

        window.location.reload();
    }, []);

    useEffect(() => {
        const backButtonListener = CapacitorApp.addListener('backButton', ({ canGoBack }) => {
            if (!canGoBack) {
                // En la página de inicio, salir de la app
                CapacitorApp.exitApp();
            }
        });
        return () => {
            // Asegurarse de limpiar el listener al desmontar el componente
            backButtonListener.remove();
        };
    }, []);

    useEffect(() => {

        // Excluir la verificación de autenticación para la ruta "/home"
        if (location.pathname === "/servicios") {
            return;
        }

        if (getUserId() !== "" || location.pathname !== "/") {
            console.log("Verificando autenticación");
            dispatch(checkAuthStatus());
        }
        if (checkAuthFail) {
            if (getUserId() !== "") localStorage.removeItem("userId");

            dispatch(logout());
            // navigation.push('/', 'root', 'replace');
        }


    }, [getUserId(), checkAuthFail, location.pathname]);



    useEffect(() => {
        // If profile is not loaded, request it
        if (location.pathname !== '/') {
            if (newContentAvailable) {
                setToastIsOpen(true);
            }
        }
    }, [newContentAvailable, location]);

    useEffect(() => {

        if (!profileLoaded && isLogged) {
            dispatch(getUserProfileRequest());
            setProfileLoaded(true);
        }
    }, [isLogged, profileLoaded]);




    if (checkAuthFail) {

        return <Redirect to="/" />;

    };
    return (

        <>
            <IonRouterOutlet>
                <Route exact path="/servicios" component={ObrabitPresentation} />
                <LoginRoute exact path="/" component={Login} userHomePath={pathByType(tipo)} />
                <PrivateRoutes path="/copropietario" component={MenuCopropietario} />

            </IonRouterOutlet>

            <IonToast
                isOpen={toastIsOpen}
                message="Una nueva actualización de la aplicación se encuentra disponible. Acepte para continuar."
                onDidDismiss={() => setToastIsOpen(false)}
                layout="stacked"
                buttons={[
                    {
                        text: 'Aceptar',
                        handler: handleReload
                    },
                    {
                        text: 'Cancelar',
                        role: 'cancel'
                    }
                ]}
            />
        </>


    );
};

export default Main;