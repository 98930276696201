import React, { useState } from "react";
import {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonInput,
    IonTextarea,
    IonAlert,
    IonLabel,
    IonIcon,
    IonHeader,
} from "@ionic/react";
import "../../styles/App.css";
import "./ReportesCopCreate.css";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { alertCircleOutline, cloudUploadOutline } from "ionicons/icons";
import ToolbarBack from "../../components/Toolbars/ToolbarBack";

// Define el tipo de los datos del formulario
type FormData = {
    titulo: string;
    contenido: string;
    imgUrl: File | null;
};

const ReportesCopCreate: React.FC = () => {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [imagePreview, setImagePreview] = useState<string | null>(null);


    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
        setValue,
        clearErrors
    } = useForm<FormData>({
        defaultValues: {
            titulo: "",
            contenido: "",
            imgUrl: null,
        },
    });

    const onSubmit = (data: FormData) => {
        console.log("Formulario enviado con datos: ", data);
        setSuccess(true);
    };
    const openFileDialog = () => {
        (document as any).getElementById('file-upload').click();
    };

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;

        // Validar el formato del archivo
        if (file.type !== "image/jpeg") {
            setError("El archivo debe ser una imagen en formato JPG.");
            setImagePreview(null);
            return;
        }

        // Mostrar previsualización de la imagen
        const reader = new FileReader();
        reader.onload = () => {
            setImagePreview(reader.result as string);
        };
        reader.readAsDataURL(file);

        // Guardar archivo en React Hook Form
        setValue("imgUrl", file);
        setError("");
    };

    const clearError = () => setError("");

    return (
        <IonPage>
            {error && (
                <IonAlert
                    isOpen={!!error}
                    header="Error"
                    message={error}
                    buttons={[{ text: "OK", handler: clearError }]}
                />
            )}
            {success && (
                <IonAlert
                    isOpen={success}
                    header="Éxito"
                    message="Reporte creado exitosamente."
                    buttons={[{ text: "OK", handler: () => setSuccess(false) }]}
                />
            )}
            <IonHeader className="ion-no-border border-header">
                <ToolbarBack title={"Crear Reporte"} backLink={`/copropietario/reportes`} />
            </IonHeader>
            <IonContent fullscreen className="ion-content-grey">
                <IonGrid>
                    <IonRow className="dashboard-row">
                        <IonCol size="12" size-lg="6">
                            <p className="font-dark-grey font-16">
                                Por favor, complete los campos a continuación para enviar su reporte.
                            </p>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="ion-margin-bottom">
                                    <div className="create-form-label-helper">
                                        <IonLabel position="stacked" className="txt-label-helper">Título</IonLabel>
                                        <small className="helper-text">Máximo 35 caracteres</small>
                                    </div>

                                    <IonInput
                                        className="create-form-input"
                                        fill="outline"
                                        placeholder="Título (opcional)"
                                        {...register("titulo", {
                                            maxLength: 35
                                        })}
                                        aria-label="Título"
                                        maxlength={35}
                                    />
                                </div>
                                <ErrorMessage
                                    errors={errors}
                                    name="titulo"
                                    render={() => (
                                        <div className="error-form">
                                            <IonIcon icon={alertCircleOutline} slot="start" />
                                            <span>El título no debe superar los 25 caracteres</span>
                                        </div>
                                    )}
                                />
                                <div className="ion-margin-bottom">
                                    <IonLabel position="stacked" className="create-form-label">
                                        Contenido <span className="required-asterisk">*</span>
                                    </IonLabel>
                                    <IonTextarea
                                        placeholder="Describe el desperfecto"
                                        {...register("contenido", {
                                            required: "El contenido es obligatorio."
                                        })}
                                        fill="outline"
                                        counter={true}
                                        maxlength={150}
                                        autoGrow={true}
                                        onIonInput={() => clearErrors("contenido")}
                                        className="create-form-input"
                                    />

                                    <ErrorMessage
                                        errors={errors}
                                        name="contenido"
                                        render={({ message }) => (
                                            <div className="error-form">
                                                <IonIcon icon={alertCircleOutline} slot="start" />
                                                <span>{message}</span>
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className="ion-margin-bottom">
                                    <IonLabel position="stacked" className="create-form-label">Imagen</IonLabel>
                                    <input
                                        type="file"
                                        accept="image/jpeg"
                                        id="file-upload"
                                        onChange={handleImageUpload}
                                        className="ion-input-upload"
                                        style={{ display: 'none' }}
                                    />
                                    <IonButton
                                        onClick={openFileDialog}
                                        expand="block"
                                        className="ion-upload-img-button"
                                    >
                                        <div className="d-flex-items-vertical">
                                            <IonIcon
                                                icon={cloudUploadOutline}
                                                className="icon-upload-button"
                                            ></IonIcon>
                                            <span className="spn-upload-image">
                                                Click para seleccionar archivo
                                            </span>
                                            <span className="spn-upload-subtitle">
                                                Formato JPG ó PNG
                                            </span>
                                        </div>
                                    </IonButton>
                                    {imagePreview && (
                                        <img
                                            src={imagePreview}
                                            alt="Previsualización"
                                            style={{ width: "100%", marginTop: "10px", borderRadius: "5px" }}
                                        />
                                    )}
                                </div>

                                <IonButton expand="block" size="large" type="submit" className="btn-guardar">
                                    Guardar
                                </IonButton>
                            </form>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default ReportesCopCreate;
