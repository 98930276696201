import {

  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  RefresherEventDetail,
  useIonViewWillEnter,
} from "@ionic/react";

import "./ObrasCopList.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";
import React, { useEffect } from "react";

import { RouteComponentProps } from "react-router";

import ToolbarMenu from "../../components/Toolbars/ToolbarMenu";
import { getObrasByEdificio } from "../../store/ducks/obras/actions";
import { IObra } from "../../store/ducks/obras/types";
import ObraCard from "../../components/ObraCards/ObraCard";
import { getEdificioCopropietario } from "../../store/ducks/edificios/actions";
import CustomSpinner from "../../components/Spinner/CustomSpinner";
import ObraListItem from "../../components/Copropietario/Obras/ObraListItem";



const ObrasCopList: React.FC = () => {
  const { edificioCopropietario } = useSelector(
    (state: RootState) => state.edificios
  );
  const { obras, obrasLoad } = useSelector(
    (state: RootState) => state.obras
  );

  const idEdificio: string = edificioCopropietario?._id;
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("edificioCopropietario1");
    console.log(edificioCopropietario);
    if (!edificioCopropietario?._id) dispatch(getEdificioCopropietario());
  }, []);
  useEffect(() => {
    console.log("edificioCopropietario");
    console.log(edificioCopropietario);
    if (edificioCopropietario?._id && !obrasLoad) {
      console.log("get obrasss");
      dispatch(getObrasByEdificio(edificioCopropietario._id));
    }
    console.log("obras");
    console.log(obras);
  }, [edificioCopropietario]);

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      if (idEdificio) {
        dispatch(getObrasByEdificio(idEdificio));
      }
      event.detail.complete();
    }, 2000);
  }

  return (
    <IonPage>
      <IonHeader className="ion-no-border border-header">

        <ToolbarMenu title="Obras" />


      </IonHeader>
      <IonContent fullscreen className="ion-content-grey">
       
            <IonRefresher slot="fixed" pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={handleRefresh}>
              <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
            <IonGrid>
              <IonRow className="dashboard-row">

                {obras.length > 0 ? (
                  obras.map((obra: IObra) => (
                    <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6" key={obra._id}>
                      <ObraListItem
                        link={`/copropietario/obras/${obra._id}`}
                        src={obra.imgUrl || "#"}
                        alt="obra"
                        obra={obra}
                      />
                    </IonCol>
                  ))
                ) : (
                  <IonCol size="12" className="center-content">
                    <p>No se encontraron obras.</p>
                  </IonCol>
                )}
              </IonRow>
            </IonGrid>
       
      </IonContent>
    </IonPage>
  );
};

export default ObrasCopList;
