import {
    IonCard,
    IonCardContent
} from "@ionic/react";


import React from "react";
import moment from "moment";
import "moment/locale/es";
import { FiCalendar } from "react-icons/fi";
import { IObra } from "../../../store/ducks/obras/types";

export type InformeDescProps = {
    obra?: IObra
};

const ObraDescripcionCard: React.FC<InformeDescProps> = ({
    obra
}) => {
    return (
        <IonCard className="ion-card-descripcion">

            <IonCardContent className="ion-card-descripcion-content">
                <div className="mt-3 mb-3">
                    {obra?.descripcion && (
                        <div>
                            <h4 className="font-dark-grey mb-1 font-weight-500">Descripción</h4>
                            <p>{obra?.descripcion}</p>
                        </div>)}
                    {obra?.estado && (
                        <div className="mt-3">
                            <h4 className="font-dark-grey mb-1 font-weight-500">Estado</h4>
                            <p className="text-justify text-capitalize">
                                {obra.estado}
                            </p>
                        </div>
                    )}
                    {obra?.avance && (
                        <div className="mt-3">
                            <h4 className="font-dark-grey mb-1 font-weight-500">Avance</h4>
                            <p>
                                {obra.avance}
                            </p>
                        </div>
                    )}
                    {obra?.fechaInicio && (
                        <div className="mt-3">
                            <h4 className="font-dark-grey mb-1 font-weight-500">Fecha Inicio</h4>
                            <p>
                                {moment(obra?.fechaInicio).locale("es").format("LL")}
                            </p>
                        </div>
                    )}
                    {obra?.fechaFin && (
                        <div className="mt-3">
                            <h4 className="font-dark-grey mb-1 font-weight-500">Fecha Fin</h4>
                            <p>

                                {moment(obra?.fechaFin).locale("es").format("LL")}
                            </p>
                        </div>
                    )}
                    {obra?.unidades && obra.unidades?.length > 0 && (<div className="mt-3">
                        <h4 className="font-dark-grey mb-1 font-weight-500">Unidades relevadas</h4>
                        <p className="text-justify">
                            {obra?.unidades?.join(" - ")}
                        </p>
                    </div>)}
                    {obra?.sectores && obra.sectores?.length > 0 && (<div className="mt-3">
                        <h4 className="font-dark-grey mb-1 font-weight-500">Sectores</h4>
                        <p>
                            {obra?.sectores?.join(" - ")}
                        </p>
                    </div>)}
                    {obra?.rubros && obra.rubros?.length > 0 && (
                        <div className="mt-3">
                        <h4 className="font-dark-grey mb-1 font-weight-500">Rubros</h4>
                        <p>
                            {obra?.rubros?.join(" - ")}
                        </p>
                    </div>)}
                </div>

            </IonCardContent>
        </IonCard>
    );
}

export default ObraDescripcionCard;