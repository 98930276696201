import {
    IonCard,
    IonCardContent
} from "@ionic/react";



import React from "react";
import moment from "moment";
import "moment/locale/es";
import { FiCalendar } from "react-icons/fi";
import { IObra } from "../../../store/ducks/obras/types";
import { IVisita } from "../../../store/ducks/visitas/types";

export type VisitaProps = {
    visita?: IVisita
};

const VisitaDescripcionCard: React.FC<VisitaProps> = ({
    visita
}) => {
    return (
        <IonCard className="ion-card-descripcion">

            <IonCardContent className="ion-card-descripcion-content">
                <div className="mt-3 mb-3">
                    {visita?.resumen && (
                        <div>
                            <h4 className="font-dark-grey mb-1 font-weight-500">Resumen</h4>
                            <p>{visita?.resumen}</p>
                        </div>)}
                    {visita?.fechaVisita && (
                        <div className="mt-3">
                            <h4 className="font-dark-grey mb-1 font-weight-500">Fecha Visita</h4>
                            <p>

                                {moment(visita?.fechaVisita).locale("es").format("LL")}
                            </p>
                        </div>
                    )}

                </div>

            </IonCardContent>
        </IonCard>
    );
}

export default VisitaDescripcionCard;