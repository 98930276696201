import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    useIonViewDidEnter,
    useIonViewWillEnter,
} from "@ionic/react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";

import ToolbarBack from "../../components/Toolbars/ToolbarBack";
import { getInformeById } from "../../store/ducks/informes/actions";
import { getObraById } from "../../store/ducks/obras/actions";
import { getVisitaById, getVisitasByObra } from "../../store/ducks/visitas/actions";
import ObraListItem from "../../components/Copropietario/Obras/ObraListItem";
import CustomSpinner from "../../components/Spinner/CustomSpinner";
import { IObra } from "../../store/ducks/obras/types";
import { IVisita } from "../../store/ducks/visitas/types";
import VisitaListItem from "../../components/Copropietario/Visitas/VisitaListItem";
import VisitaDescripcionCard from "../../components/Copropietario/Visitas/VisitaDescriptionCard";
import { getObservacionesByVisita } from "../../store/ducks/observaciones/actions";
import VisitaDetallesItem from "../../components/Copropietario/Visitas/VisitaDetallesItem";
import { alertCircle, alertCircleOutline, clipboardOutline } from "ionicons/icons";
import ObservacionDetallesCard from "../../components/Copropietario/Observaciones/ObservacionesDetallesCard";
import { IObservacion } from "../../store/ducks/observaciones/types";

type Params = { obraId: string | undefined, visitaId: string | undefined };

const ObraCopVisita: React.FC<RouteComponentProps<Params>> = ({
    match: {
        params: { obraId, visitaId }
    },
}) => {
    const { _id, userProfile } = useSelector((state: RootState) => state.users);
    const { obraByIdLoad, obraById } = useSelector((state: RootState) => state.obras);
    const { visitasByObra, visitasLoad, visitaById, visitaByIdLoad } = useSelector((state: RootState) => state.visitas);

    const { observacionesByVisita, observacionesLoad } = useSelector((state: RootState) => state.observaciones);
    const toolbarTitle = visitaById?.titulo || "Visita";
    const dispatch = useDispatch();
    useEffect(() => {
        console.log(visitaById);
        if (visitaByIdLoad && visitaId && !observacionesLoad) dispatch(getObservacionesByVisita(visitaId));
    }, [visitaById, observacionesByVisita]);
    useIonViewWillEnter(() => {
        console.log(visitaId);
        if (visitaId && !visitaByIdLoad) dispatch(getVisitaById(visitaId));

    });


    return (
        <IonPage>
            <IonHeader className="ion-no-border border-header">
                <ToolbarBack title={toolbarTitle} backLink={`/copropietario/obras/seguimiento/${obraId}`} />
            </IonHeader>
           
                <IonContent fullscreen className="ion-content-grey">


                    <IonGrid>
                        <IonRow className="dashboard-row">
                          
                            <IonCol size-md="12" size-lg="12" size-xs="12" size-sm="12">

                                <VisitaDescripcionCard visita={visitaById} />

                            </IonCol>
                        </IonRow>

                        <IonRow className="dashboard-row">
                            <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                <VisitaDetallesItem
                                    link={`/copropietario/obras/seguimiento/${obraId}/visitas/${visitaId}/observaciones`}
                                    title="Observaciones"
                                    subTitle={`Correcciones solicitadas (${observacionesByVisita.length})`}
                                    iconName={alertCircle}
                                />
                            </IonCol>

                        </IonRow>
                        
                    </IonGrid>


                </IonContent>
            

        </IonPage>
    );
};

export default ObraCopVisita;